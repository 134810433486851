<template>
  <component :is="layoutComponent"><slot /></component>
</template>

<script>
import { computed } from "@vue/runtime-core";
import useIsMobile from "@/composables/useIsMobile";

export default {
  props: {
    layoutMobileComponent: Object,
    layoutDesktopComponent: Object,
  },

  setup(props) {
    const { isMobile } = useIsMobile();

    const layoutComponent = computed(() => {
      if (isMobile.value) return props.layoutMobileComponent;

      return props.layoutDesktopComponent;
    });

    return {
      layoutComponent,
    };
  },
};
</script>
